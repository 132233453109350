import './bootstrap';
import '../css/app.scss';
import * as Sentry from "@sentry/vue";
import translateHelper from './base';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {createI18n} from 'vue-i18n';

import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';

import {createVfm} from 'vue-final-modal'
import {LoadingPlugin} from 'vue-loading-overlay';
import {inertiaRoutingInstrumentation} from "@/route_utils";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/styles";

import emitter from "./core/event.ts";


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    async setup({el, App, props, plugin}) {
        const i18n = createI18n({
            locale: props.initialPage.props.locale, // user locale by props
            fallbackLocale: "en", // set fallback locale
            legacy: false,
            fallbackWarn: false,
            missingWarn: false,
            messages: {
                'en': await (await fetch(`/locale/en.json`)).json(),
                'da': await (await fetch(`/locale/da.json`)).json(),
            }
        });
        window.i18n = i18n

        const vfm = createVfm()

        const app = createApp({render: () => h(App, props)})
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: inertiaRoutingInstrumentation,
                }),
                new Sentry.Replay(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/virtual\.datesite\.zxsnow\.com\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        // const hasListeners = <T extends keyof Events>(eventName: T): boolean => {
        //     return !!(emitter.all[eventName] && emitter.all[eventName].length);
        // };

        const hasListeners = (eventName: string): boolean => {
            return !!(emitter.all[eventName] && emitter.all[eventName].length);
        };

        window.addEventListener('popstate', (event) => {
            if (hasListeners('popstate')) {
                console.log('popstate')
                event.stopImmediatePropagation();
            }
            emitter.emit('popstate', event)
        });


        return app
            .use(plugin)
            .use(LoadingPlugin)
            .use(vfm)
            .use(i18n)
            .use(SnackbarService)
            .use(ZiggyVue, Ziggy)
            .mixin(translateHelper)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
